<template>
	<div class="cookieAlert" v-if="show">
		<div class="container">
			<p>Веб-сайт использует cookie (файлы с данными о прошлых посещениях сайта). Просматривая сайт, Вы подтверждаете своё согласие с условиями применения cookie.</p>
			<a class="cookieAlert--close icon-close" @click="hideAlert()"></a>
		</div>
	</div>
</template>




<style lang="sass">
	@import '@/assets/sass/CookieAlert'
</style>

<script>
	export default {
		data: () => ({
			show: false,
			routeIterator: 0
		}),
		methods: {
			hideAlert: function(){
				this.show = false;
			},
			getCookie: function(name){
				var matches = document.cookie.match(
					new RegExp(
						"(?:^|; )" +
							name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") +
							"=([^;]*)"
					)
				);
				return matches ? decodeURIComponent(matches[1]) : undefined;
			},
			setCookie: function(name, value, options){
				options = options || {};

				var expires = options.expires;

				if (typeof expires == "number" && expires) {
					var d = new Date();
					d.setTime(d.getTime() + expires * 1000);
					expires = options.expires = d;
				}
				if (expires && expires.toUTCString) {
					options.expires = expires.toUTCString();
					options.domain = location.hostname;
					options.path = "/";
				}

				value = encodeURIComponent(value);

				var updatedCookie = name + "=" + value;

				for (var propName in options) {
					updatedCookie += "; " + propName;
					var propValue = options[propName];
					if (propValue !== true) {
						updatedCookie += "=" + propValue;
					}
				}

				document.cookie = updatedCookie;
			}
		},
		beforeMount(){
			if(this.getCookie("cookieAlert")){
				this.show = false;
			} else{
				this.show = true;
				this.setCookie("cookieAlert", "Y", {
					expires: 86400 * 365,
					domain: "artrockets.ru",
					path: "/"
				});
			}
		},
		watch: {
			'$route': function(){
				this.routeIterator++;
				if(this.routeIterator > 1){
					this.show = false;
				}
			}
		}
	}
</script>